import React from "react";
import {
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,

	Box,
	Grid,
	Button,
	Checkbox,
	Backdrop,
	Skeleton,
	TextField,
	Typography,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	DragHandle as DragHandleIcon
} from "@mui/icons-material";
import {
	SortableContainer,
	SortableElement,
	SortableHandle
} from "react-sortable-hoc";
import {
	DialogConfirmation
} from "../../../../../components";
import {
	Notification,
	NotificationTypes
} from "../../../../../common/Notification"
import agent from "../../../../../agent/agent";
import ProductRow from "./ProductRow";
import DialogAddProduct from "./DialogAddProduct";

Array.prototype.insert = function ( index, item ) {
	this.splice( index, 0, item );
};

class ProductsCollection extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			productSet: [],
			fullProductSet: [],
			shopCharacteristicGroups: [],
			selectedRows: [],

			filter: {
				name: "",
				code: "",
			},

			isShowBackdrop: false,
			isLoadProduct: true
		}

		this.refDialogConfirmation = React.createRef();
		this.refDialogAddProduct = React.createRef();
		this.timeOutFilterProductSet = null;
	}

	componentDidMount = async () => {
		await this.getProductsCollection();
		await this.getShopCharacteristicGroups();
	}

	// Получение списка товаров
	getProductsCollection = async () => {
		const {product} = this.props;
		const url = `/admin/api-shop-product-set/list?per-page=500&filter[product_id]=${ product.id }&expand=relatedProduct&fields=id,product_id,price_new,status,related_product_id,sort,quantity,price_coefficient,relatedProduct.name,relatedProduct.price_new,relatedProduct.main_photo_id,relatedProduct.characteristic_group_id,relatedProduct.code,relatedProduct.status`
		const response = await agent.get(url).then((res) => {
			return res.data || []
		}).catch(() => {
			return []
		})

		const products = response
			.sort((a, b) => {
			if (+a.sort < +b.sort) {
				return +1
			}
			if (+a.sort > +b.sort) {
				return -1
			}
			return 0
		})
			.map((product, index) => {
				return {
					...product,
					sort: String(response.length - index)
				}
			})

		this.setState({
			productSet: products,
			fullProductSet: products,
			selectedRows: [],
			isLoadProduct: false
		})
	}

	// Получение всех типов товаров
	getShopCharacteristicGroups = async () => {
		const url = `/admin/api-shop-characteristic-groups/list?ngrestCallType=list&expand=productsCount%2CcharacteristicsCount&fields=id%2Cid%2Cimage_id%2Cname%2Cslug%2Csort%2Cis_for_product_set%2CproductsCount%2CcharacteristicsCount&sort=id&page=1`;
		const response = await agent.get(url).then((res) => {
			return res.data
		}).catch((err) => {
			return null
		})
		this.setState({
			shopCharacteristicGroups: response
		})
	}

	// Удаление товара
	removeProduct = async (product, isConfirm, isHideNotification = false) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите удалить товар из коллекции?",
				acceptButtonAction: this.removeProduct.bind(this, product, true)
			})

			return null
		}

		this.setState({ isShowBackdrop: true })

		const response = await agent.delete(`${process.env.REACT_APP_HOST_API}admin/api-shop-product-set/${ product.id }`).then((res) => {
			return true
		}).catch(() => {
			return false
		})
		if (!response) {
			this.setState({ isShowBackdrop: false })

			Notification({
				message: "Произошла ошибка при удалении",
				type: NotificationTypes.error
			})

			return
		}

		await this.getProductsCollection();
		this.setState({ isShowBackdrop: false })
		if (!isHideNotification) {
			Notification({
				message: "Товар успешно удален",
				type: NotificationTypes.success
			})
		}
	}
	multiRemoveProduct = async (isConfirm) => {
		if (!isConfirm) {
			this.refDialogConfirmation.current.onOpen({
				title: "Подтверждение",
				message: "Вы действительно хотите удалить товары из коллекции?",
				acceptButtonAction: this.multiRemoveProduct.bind(this, true)
			})

			return
		}

		const selectedRows = [...this.state.selectedRows];
		await Promise.all(selectedRows.map(async (productId) => {
			await this.removeProduct({
				id: productId
			}, true);
		}));
	}

	// Добавление товара
	addProduct = async ({ productIds } = {}) => {
		if ((productIds || []).length <= 0) {
			this.refDialogAddProduct.current.open({
				onSubmit: this.addProduct.bind(this)
			})

			return
		}

		this.setState({ isShowBackdrop: true })

		await Promise.all(productIds.map(async (productId) => {
			const formData = {
				"product_id": this.props.product.id,
				"sort": "0",
				"quantity": 1,
				"related_product_id": String(productId),
				"price_coefficient": "0.00"
			}
			await agent.post(`${process.env.REACT_APP_HOST_API}admin/api-shop-product-set`, formData).then((res) => {
				return res.data
			}).catch((err) => {
				return { error: err.response }
			})
		}))

		await this.getProductsCollection();

		this.refDialogAddProduct.current.close();

		Notification({
			type: NotificationTypes.success,
			message: "Товар успешно добавлен"
		})

		this.setState({ isShowBackdrop: false })
	}

	// Изменение товара
	changeProductPriceCoefficient = async (form) => {
		this.setState({ isShowBackdrop: true })

		let body = {
			"id": form.id,
			"product_id": form.product_id,
			"related_product_id": form.related_product_id,
			"sort": form.sort,
			"quantity": form.quantity,
			"price_coefficient": form.price_coefficient
		}
		const response = await agent.put(`/admin/api-shop-product-set/${ form.id }`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false })

			Notification({
				type: NotificationTypes.error,
				message: "Возникла ошибка"
			})

			return
		}

		Notification({
			type: NotificationTypes.success,
			message: "Успешно изменено"
		})

		await this.getProductsCollection();

		this.setState({ isShowBackdrop: false })
	}
	changeProductsMulti = async () => {
		this.setState({ isShowBackdrop: true })

		const products = [...(this.state?.productSet || [])];

		const body = {
			product_set_parent_id: String(this.props.product.id),
			data: {}
		}
		products.map((item) => {
			body.data[item.related_product_id] = String(item.sort);
		})
		const response = await agent.put(`/admin/api-shop-product-set/multiple-update-sort`, body).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response || true }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false })

			Notification({
				type: NotificationTypes.error,
				message: "Возникла ошибка при изменении сортировки, попробуйте позднее"
			})

			return null
		}

		await this.getProductsCollection();

		this.setState({ isShowBackdrop: false })

		Notification({
			type: NotificationTypes.success,
			message: "Успешно изменено"
		})
	}
	changeProductSort = async ({ oldIndex, newIndex }) => {
		let productSet = [...this.state.productSet];

		if (oldIndex === newIndex) {
			return
		}

		let productPrimary = {...productSet?.[oldIndex]};

		productSet.splice(oldIndex, 1);
		productSet.insert(newIndex, productPrimary);
		productSet = productSet.map((product, index) => {
			return {
				...product,
				sort: String(productSet.length - index)
			}
		})

		await this.setState({productSet})
	}

	changeFilter = async ({ target }) => {
		clearTimeout(this.timeOutFilterProductSet);

		const { name, value } = target;

		let filter = {...this.state.filter};
		filter[name] = value;
		await this.setState({ filter });

		this.timeOutFilterProductSet = setTimeout(async () => {
			await this.setFilterProductSet();
		}, 1000);
	}
	setFilterProductSet = async () => {
		const searchName = (this.state.filter?.name || "").toLowerCase();
		const searchCode = (this.state.filter?.code || "").toLowerCase();

		let productSet = [...this.state.fullProductSet];
		productSet = productSet.filter((t) => {
			const tName = (t?.relatedProduct?.name || "").toLowerCase();
			const tCode = (t?.relatedProduct?.code || "").toLowerCase();

			return Boolean(
				tName.indexOf(searchName) > -1 &&
				tCode.indexOf(searchCode) > -1
			)
		});

		this.setState({
			productSet,
			selectedRows: []
		})
	}

	changeSelectedRows = (productId) => {
		let selectedRows = [...this.state.selectedRows];

		const findIndex = selectedRows.findIndex((t) => t === productId);
		if (findIndex > -1) {
			selectedRows.splice(findIndex, 1);
		} else {
			selectedRows.push(productId)
		}

		this.setState({
			selectedRows
		})
	}
	fullChangeSelectedRows = () => {
		const isFullSelected = Boolean(this.state.productSet.length === this.state.selectedRows.length);

		let selectedRows = [];
		if (!isFullSelected) {
			selectedRows = [...this.state.productSet].map((t) => t.id);
		}

		this.setState({
			selectedRows
		})
	}
	clearSelectedRows = () => {
		this.setState({
			selectedRows: [],
		})
	}

	render() {
		const {
			classes
		} = this.props;
		const {
			productSet,
			selectedRows,
			shopCharacteristicGroups,

			filter,

			isShowBackdrop,
			isLoadProduct
		} = this.state;

		return (
			<Box position="relative">

				{productSet.length > 5 && (
					<Button
						sx={{marginBottom: "10px"}}
						fullWidth
						variant="outlined"
						color="primary"
						onClick={() => this.addProduct()}
					>Добавить товар</Button>
				)}

				<Box mt={2} mb={2}>
					<Grid container spacing={2}>
						<Grid sx={{flex: 1}} item>
							<TextField
								value={filter.name}
								name="name"
								label="Название товара"
								placeholder="Введите название товара"
								size="small"
								fullWidth
								onChange={this.changeFilter}
							/>
						</Grid>
						<Grid sx={{flex: 1}} item>
							<TextField
								value={filter.code}
								name="code"
								label="Артикул"
								placeholder="Введите aртикул"
								size="small"
								fullWidth
								onChange={this.changeFilter}
							/>
						</Grid>
					</Grid>
				</Box>

				<Box mb={2}>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<Typography variant="subtitle1">
								Выбранно товаров: {selectedRows.length}шт;
							</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="outlined"
								size="small"
								disabled={Boolean(selectedRows.length <= 0)}
								onClick={this.multiRemoveProduct.bind(this, false)}
							>Удалить</Button>
						</Grid>
					</Grid>
				</Box>

				<SortableListContainer
					items={productSet}
					classes={classes}
					selectedRows={selectedRows}
					shopCharacteristicGroups={shopCharacteristicGroups}

					isLoad={isLoadProduct}

					useDragHandle={true}
					lockAxis="y"

					onSortEnd={this.changeProductSort}
					onRemoveProduct={this.removeProduct}
					onChangeProductPriceCoefficient={this.changeProductPriceCoefficient}

					onClearSelectedRows={this.clearSelectedRows}
					onChangeSelectedRows={this.changeSelectedRows}
					onFullChangeSelectedRows={this.fullChangeSelectedRows}
				/>

				<Button
					sx={{marginTop: "10px"}}
					fullWidth
					variant="outlined"
					color="primary"
					onClick={() => this.addProduct()}
				>Добавить товар</Button>

				<Box pb="64px"/>

				<Box className={classes.stickyInfoSave}>
					<Typography>После всех изменений сортировок товаров, нажмите сохранить</Typography>
					<Button
						fullWidth
						variant="contained"
						onClick={this.changeProductsMulti}
					>Сохранить</Button>
				</Box>

				<DialogConfirmation
					ref={this.refDialogConfirmation}
				/>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress color="primary"/>
				</Backdrop>


				<DialogAddProduct
					ref={this.refDialogAddProduct}
				/>

			</Box>
		);
	}
}

const SortableListContainer = SortableContainer(
	({
		 items,
		 isLoad,
		 classes,
		 selectedRows,

		 onRemoveProduct,
		 shopCharacteristicGroups,
		 onChangeProductPriceCoefficient,

		 onChangeSelectedRows,
		 onFullChangeSelectedRows
	 }) => (
	<Table className={classes.table}>
		<TableHead>
			<TableRow>
				<TableCell width={20}>
					<Checkbox
						checked={Boolean(items.length === selectedRows.length)}
						sx={{color: "white!important"}}
						onChange={onFullChangeSelectedRows}
					/>
				</TableCell>
				<TableCell>Фото</TableCell>
				<TableCell>Название товара</TableCell>
				<TableCell>Цена</TableCell>
				<TableCell>Артикул</TableCell>
				<TableCell>Тип товара</TableCell>
				<TableCell width={120} align="center">Вход в стоимость</TableCell>
				<TableCell width={42} align="right"/>
				<TableCell width={42} align="right"/>
			</TableRow>
		</TableHead>
		<TableBody>
			<VisibleContent visible={!isLoad}>
				{items.map((product, index) => (
					<SortableItem
						index={index}
						key={`row-product-id-${ product.id }`}
						product={product}
						selectedRows={selectedRows}
						shopCharacteristicGroups={shopCharacteristicGroups}

						onRemoveProduct={onRemoveProduct}
						onChangeSelectedRows={onChangeSelectedRows}
						onChangeProductPriceCoefficient={onChangeProductPriceCoefficient}
					/>
				))}
			</VisibleContent>
			<VisibleContent visible={isLoad}>
				{[0,1,2].map((any, index) => (
					<TableRow>
						<TableCell width={20}>
							<Skeleton/>
						</TableCell>
						<TableCell width={50}>
							<Skeleton variant="rectangular" width={50} height={50}/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
						<TableCell>
							<Skeleton variant="rectangular"/>
						</TableCell>
					</TableRow>
				))}
			</VisibleContent>
		</TableBody>
	</Table>
))
const VisibleContent = React.memo((props) => {
	if (!props.visible) {
		return null
	}

	return props.children
})
const SortableItem = SortableElement(({ product, selectedRows, onRemoveProduct, onChangeProductPriceCoefficient, shopCharacteristicGroups, onChangeSelectedRows }) => (
	<ProductRow
		product={product}
		shopCharacteristicGroups={shopCharacteristicGroups}
		selectedRows={selectedRows}
		onRemoveProduct={onRemoveProduct}
		onChangeSelectedRows={onChangeSelectedRows}
		onChangeProductPriceCoefficient={onChangeProductPriceCoefficient}

		DragHandle={DragHandle}
	/>
))
const DragHandle = SortableHandle(() => (
	<Box sx={{ height: 42, cursor: "pointer", display: "flex", alignItems: "center", justifyItems: "center" }}>
		<DragHandleIcon/>
	</Box>
))

const styles = {
	table: {
		borderSpacing: "initial",

		"& .MuiTableRow-head .MuiTableCell-root": {
			padding: "4px 8px"
		}
	},

	stickyInfoSave: {
		position: "sticky",
		left: 0, right: 0, bottom: 0,
		maxWidth: 640,
		background: "white",
		boxShadow: "0 0 5px -1px #0000008c",
		margin: "0 auto",
		padding: "8px 12px",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		gap: "8px",

		"& .MuiTypography-body1": {
			fontSize: "18px",
			lineHeight: "1.2",
			fontWeight: "500",
		}
	}
}
ProductsCollection = withStyles(styles)(ProductsCollection)

export default ProductsCollection
